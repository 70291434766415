import * as React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";
import style from "../styles/dzierzawa-krok-po-kroku.module.scss";

const DzierzawaKrokPoKrokuPage = () => {
  return (
    <Layout>
      <Container className="p-5">
        <h1 class="text-center">Dzierżawa krok po kroku</h1>
      </Container>
      <article className={style.stepByStep}>
        <p>
          Skontaktuj się z nami w celu oceny przydatności terenu pod farmę
          fotowoltaiczną.
        </p>
        <p>
          Po pozytywnej weryfikacji podpisanie umowy dzierżawy z opłatą roczną,
          podczas której będziemy uzyskiwali wszelkie pozwolenia potrzebne do
          budowy farmy.
        </p>
        <p>
          Istnieje możliwość dotychczasowego użytkowania działki, aż do momentu
          rozpoczęcia budowy farmy.
        </p>
        <p>
          O rozpoczęciu budowy poinformujemy Cię 30 dni wcześniej. Jeżeli
          w związku z tym nie będzie możliwy już zaplanowany np. zbiór zboża -
          zwrócimy Ci poniesione koszty.
        </p>
        <p>Istotny wzrost przychodów z dzierżawy po rozpoczęciu budowy.</p>
        <p>
          W trakcie długoletniej dzierżawy, zadbamy o wszystko za Ciebie - nie
          trzeba żadnych wysiłków z Twojej strony, z wyjątkiem aktualizowania
          numeru konta bankowego, na który trafią przelewy.
        </p>
        <p>
          Po zakończeniu działania farmy fotowoltaicznej zdemontujemy i
          zutylizujemy wszystkie urządzenia farmy fotowoltaicznej, tak, by
          działka ponownie mogła mieć przeznaczenie rolnicze.
        </p>
      </article>
      <Container className="p-3 lh-lg">
        <h4>Zalety</h4>
        <ul>
          <li>
            Możliwość dotychczasowego użytkowania gruntu do czasu rozpoczęcia
            budowy.
          </li>
          <li>
            Szansa na ekologiczne, innowacyjne zagospodarowanie leżących
            odłogiem gruntów.
          </li>
          <li>
            Wieloletnie, stabilne przychody (umowa dzierżawy na 29 lat) nie
            wymagające nakładu pracy. (Ty odpoczywasz, a działka na ciebie
            zarabia!).
          </li>
          <li>
            Zabezpieczenie rodziny (przychody z dzierżawy przekazywane są
            spadkobiercom).
          </li>
          <li>
            Bezstresowy proces realizacji przedsięwzięcia - my zajmiemy się
            wszystkim za Ciebie.
          </li>
        </ul>
      </Container>
    </Layout>
  );
};

export default DzierzawaKrokPoKrokuPage;
